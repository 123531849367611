import html from "../images/technologies/html.svg";
import css from "../images/technologies/css.svg";
import javascript from "../images/technologies/js.svg";
import typescript from "../images/technologies/ts.svg";
import react from "../images/technologies/react.svg";
import node from "../images/technologies/node.svg";
import express from "../images/technologies/express.svg";
import mongoDB from "../images/technologies/mongo.svg";
import firebase from "../images/technologies/firebase.svg";
import git from "../images/technologies/git.svg";
import npm from "../images/technologies/npm.svg";
import jest from "../images/technologies/jest.svg";
import python from "../images/technologies/python.svg";
import webpack from "../images/technologies/webpack.svg";
import django from "../images/technologies/django.svg";
import flask from "../images/technologies/flask.svg";
import bootstrap from "../images/technologies/bootstrap.svg";
import sqlite3 from "../images/technologies/sqlite3.svg";
import atlas from "../images/technologies/atlas.svg";

const technologyList = [
  {id: 1, name: "JavaScript", image: javascript},
  {id: 2, name: "TypeScript", image: typescript},
  {id: 3, name: "Python", image: python},
  {id: 4, name: "HTML", image: html },
  {id: 5, name: "CSS", image: css },
  {id: 6, name: "React", image: react},
  {id: 7, name: "NPM", image: npm},
  {id: 8, name: "Webpack", image: webpack},
  {id: 9, name: "Jest", image: jest},
  {id: 10, name: "Node", image: node},
  {id: 11, name: "Express", image: express},
  {id: 12, name: "Django", image: django},
  {id: 13, name: "Flask", image: flask},
  {id: 14, name: "Bootstrap", image: bootstrap},
  {id: 15, name: "MongoDB", image: mongoDB},
  {id: 16, name: "SQLite3", image: sqlite3},
  {id: 17, name: "Firebase", image: firebase},
  {id: 18, name: "Atlas", image: atlas},
  {id: 19, name: "GIT", image: git},
  
  
];

export default technologyList