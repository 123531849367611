import immoscout from "../images/projects/immoscout.gif";
import immoscoutFallback from "../images/projects/immoscout.png";
import battleshipPlacement from "../images/projects/battleshipPlacement.gif";
import battleshipPlacementFallback from "../images/projects/battleshipPlacement.png";
import battleshipBattle from "../images/projects/battleshipBattle.gif";
import battleshipBattleFallback from "../images/projects/battleshipBattle.png";
import barbershopLanding from "../images/projects/barbershopLanding.gif";
import barbershopLandingFallback from "../images/projects/barbershopLanding.png";
import barbershopForm from "../images/projects/barbershopForm.gif";
import barbershopFormFallback from "../images/projects/barbershopForm.png";
import shoppingCart from "../images/projects/shoppingCart.gif";
import shoppingCartFallback from "../images/projects/shoppingCart.png";
import whereiswaldo from "../images/projects/whereiswaldo.gif";
import whereiswaldoFallback from "../images/projects/whereiswaldo.png";
import cvCreator from "../images/projects/cv-creator.gif";
import cvCreatorFallback from "../images/projects/cv-creator.png";
import ankiweb1 from "../images/projects/ankiweb1.gif";
import ankiweb1Fallback from "../images/projects/ankiweb1.png";
import ankiweb2 from "../images/projects/ankiweb2.gif";
import ankiweb2Fallback from "../images/projects/ankiweb2.png";

function Works() {
  return (
    <div className="works">
      <h2>Works</h2>
      <article className="light">
        <div className="text">
          <h3>Barbershop project</h3>
          <p>
            Fullstack project of imaginary barbershop. Consists of <em>landing page</em>, <em>complicated react from</em> and 
            <em> REST API</em> server on Express.
          </p>
          <b>Landing page: HTML, SASS, JS</b>
          <b>Form: React, Typescript, HTML, SASS</b>
          <b>REST api: Node, express, MongoDB, Atlas, JS</b>
          <div className="work-buttons">
            <a
              href="https://blanding.hikyn.dev/"
              target="_blank"
              rel="noreferrer"
            >
              View live app
            </a>
            <a
              href="https://github.com/Hikyn/barbershop-project/"
              target="_blank"
              rel="noreferrer"
            >
              View repository
            </a>
          </div>
        </div>
        <div className="image">
          <img src={barbershopLanding} alt="" style={{ backgroundImage:`url(${barbershopLandingFallback})`}}/>
          <img src={barbershopForm} alt="" style={{ backgroundImage:`url(${barbershopFormFallback})`}}/>
        </div>
      </article>
      <article className="dark">
        <div className="text">
          <h3>Real Estate Scraper (Immobilienscout)</h3>
          <p>
            Python application that scrapes top 1 German real estate website. 
          </p>
          <p>Allows scraping houses with different parameters, saves them as json and in SQL.</p>
          <p>Outputs information via REST api on Flask to telegram bot, which sends new listings to users as soon as they appear, based on their filters.</p>
          <div className="work-buttons">
            <p className="inactive">
              Only on request
            </p>
          </div>
        </div>
        <div className="image">
          <img src={immoscout} alt="" style={{ backgroundImage:`url(${immoscoutFallback})`}}/>
        </div>
      </article>
      <article className="light">
        <div className="text">
          <h3>Anki-based Card Learning</h3>
          <p>
            Python application that facilitates learning of new words/concepts.
          </p>
          <p>Create a new categories and topics and learn new words</p>
          <p>Made with Bootstrap before learning JS, CSS and React</p>
          <p>User authentification allows to customize experience for each user. Shows progress and stats for each day.</p>
          <b>Python, Bootstrap, Flask, SQLite3</b>
          <div className="work-buttons">
            <a
              href="https://hikyn.pythonanywhere.com/"
              target="_blank"
              rel="noreferrer"
            >
              View live app
            </a>
            <a
              href="https://github.com/Hikyn/anki-based-web-learning"
              target="_blank"
              rel="noreferrer"
            >
              View repository
            </a>
          </div>
        </div>
        <div className="image">
          <img src={ankiweb1} alt="" style={{ backgroundImage:`url(${ankiweb1Fallback})`}}/>
          <img src={ankiweb2} alt="" style={{ backgroundImage:`url(${ankiweb2Fallback})`}}/>
        </div>
      </article>
      <article className="dark">
        <div className="text">
          <h3>Where is Waldo?</h3>
          <p>
            A single page website for the famous game called <em>"Where is Waldo?"</em>
          </p>
          <p>- Leaderboards, timer, dynamic click position</p>
          <b>React, Typescript, Firebase, HTML, CSS</b>
          <div className="work-buttons">
            <a href="https://waldo.hikyn.dev" target="_blank" rel="noreferrer">
              View live site
            </a>
            <a
              href="https://github.com/Hikyn/whereiswaldo"
              target="_blank"
              rel="noreferrer"
            >
              View repository
            </a>
          </div>
        </div>
        <div className="image">
          <img src={whereiswaldo} alt="" style={{ backgroundImage:`url(${whereiswaldoFallback})`}}/>
        </div>
      </article>
      <article className="light">
        <div className="text">
          <h3>Shopping cart</h3>
          <p>
            Example website made for learning Test Driven Development with React and Typescript.
          </p>
          <b>Typescript, React, JEST, HTML, CSS</b>
          <div className="work-buttons">
            <a
              href="https://shopcart.hikyn.dev"
              target="_blank"
              rel="noreferrer"
            >
              View live app
            </a>
            <a
              href="https://github.com/Hikyn/react-shopping-cart"
              target="_blank"
              rel="noreferrer"
            >
              View repository
            </a>
          </div>
        </div>
        <div className="image">
          <img src={shoppingCart} alt="" style={{ backgroundImage:`url(${shoppingCartFallback})`}}/>
        </div>
      </article>
      <article className="dark">
        <div className="text">
          <h3>CV creator</h3>
          <p>
            Small app for when I just started to learn React.
          </p>
          <b>React, JS, HTML, CSS</b>
          <div className="work-buttons">
            <a
              href="https://cvcreator.hikyn.dev"
              target="_blank"
              rel="noreferrer"
            >
              View site
            </a>
            <a
              href="https://github.com/Hikyn/cv-creator"
              target="_blank"
              rel="noreferrer"
            >
              View repository
            </a>
          </div>
        </div>
        <div className="image">
          <img src={cvCreator} alt="" style={{ backgroundImage:`url(${cvCreatorFallback})`}}/>
        </div>
      </article>
      <article className="light">
        <div className="text">
          <h3>Project Battleship</h3>
          <p>
            Project created using TDD with Vanilla JS and covered in tests with JEST.
          </p>
          <div className="work-buttons">
            <a
              href="https://battleships.hikyn.dev"
              target="_blank"
              rel="noreferrer"
            >
              View live app
            </a>
            <a
              href="https://github.com/Hikyn/Project-Battleship"
              target="_blank"
              rel="noreferrer"
            >
              View repository
            </a>
          </div>
        </div>
        <div className="image">
          <img src={battleshipPlacement} alt="" style={{ backgroundImage:`url(${battleshipPlacementFallback})`}}/>
          <img src={battleshipBattle} alt="" style={{ backgroundImage:`url(${battleshipBattleFallback})`}}/>
        </div>
      </article>
    </div>
  );
}

export default Works;
