import { motion } from "framer-motion";
import avatar from "../images/avatar.jpg";

function LeftBar() {
  const variants = {
    hidden: {
      opacity: 0,
      x: "-5vh",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        damping: 32,
      },
    },
  };

  return (
    <motion.section
      initial="hidden"
      animate="visible"
      variants={variants}
      className="left"
    >
      <div className="sidebar-toggle-btn">
        <i className="fa-solid fa-xmark"></i>
      </div>
      <div className="info-bar">
        <div className="info-header">
          <div className="info-avatar">
            <img src={avatar} alt="" />
          </div>
          <h2 className="info-name">Danila Rzhevskii</h2>
          <p className="info-title">Fullstack Developer</p>
          <p className="info-email">
            <i className="fa-solid fa-envelope"></i> danila.rzhevskii@gmail.com
          </p>
        </div>
        <div className="info-body">
          <div className="body-details">
            <h4>Residence:</h4> <span>Greece</span>
            <h4>City:</h4> <span>Athens</span>
            <h4>Language:</h4> <span>English</span>
          </div>
        </div>
        <div className="info-socials">
          <a
            href="https://www.linkedin.com/in/danila-rzhevskii-098a30271/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin-in"></i>
          </a>
          <a href="https://github.com/Hikyn" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-github"></i>
          </a>
          <a href="mailto:danila.rzhevskii@gmail.com">
            <i className="fa-solid fa-envelope"></i>
          </a>
        </div>
      </div>
    </motion.section>
  );
}

export default LeftBar;
