function Footer() {
  return (
    <footer>
      <h2>Contact me</h2>
      <div className="footer-links">
        <div>
          <a
            href="https://www.linkedin.com/in/danila-rzhevskii-098a30271/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin-in"></i>
            <p>linkedin.com/in/danila-rzhevskii/</p>
          </a>
        </div>
        <div>
          <a href="https://github.com/Hikyn" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-github"></i>
            <p>github.com/Hikyn</p>
          </a>
        </div>

        <div>
          <a href="mailto: danila.rzhevskii@gmail.com">
            <i className="fa-solid fa-envelope"></i>
            <p>darzhevskii@gmail.com</p>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
